@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/Helvetica/Helvetica.ttf') format('truetype');
}

@font-face {
  font-family: 'Rising sun';
  src: url('./fonts/rising-sun/RisingSun-Regular.otf') format('opentype');
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Helvetica';
  letter-spacing: 0.025em;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.4);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.7);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(128, 128, 128, 0.5);
}
